import React from 'react'
import call from "../images/call.png"
import map from "../images/map.png"
import youtube from "../images/youtube.png"
import { Link } from 'gatsby'

export default function BlockFooter() {
    return (
        <section class="bg-tertiary">
            <div class="px-5 md:py-10 2xl:py-12 mx-auto">
                <div class="flex flex-wrap">
                    <div class="relative w-full md:w-1/3 py-8 md:py-4 md:px-10 border-b border-secondary md:border-none">
                        <div class="h-full text-center">
                            <img
                                class="w-8 md:w-10 2xl:w-12 mb-2 md:mb-6 object-cover object-center  inline-block "
                                src={map}
                                alt="Find a Distributor"
                                
                            />
                            <h3 className=' md:text-lg 2xl:text-xl text-primary '>Find a Distributor</h3>
                            <p class="font-medium text-sm md:text-base 2xl:text-lg mb-3 md:mb-4">Search by country to find a Gazelle® distributor closest to you</p>
                            <Link to="/distributors" className='absolute bottom-4 md:bottom-0 w-40 mr-auto ml-auto left-0 right-0 underline font-medium text-sm md:text-base 2xl:text-lg'>Find a Distributor</Link>
                        </div>
                    </div>
                    <div class="relative w-full md:w-1/3  py-8  md:py-4 md:px-10 border-b border-secondary md:border-none">
                        <div class="h-full text-center">
                            <img
                                class="w-8 md:w-10 2xl:w-12 mb-2 md:mb-6 object-cover object-center inline-block "
                                src={call}
                                alt="Contact Us"
                                
                            />
                            <h3 className=' md:text-lg 2xl:text-xl text-primary '>Contact Us</h3>
                            <p class="font-medium text-sm md:text-base 2xl:text-lg mb-3 md:mb-4">Have questions? We are here to help.</p>
                            <Link to="/contact-us" className='absolute bottom-4 md:bottom-0 w-40 mr-auto ml-auto left-0 right-0 underline font-medium text-sm md:text-base 2xl:text-lg'>Contact Us</Link>
                        </div>
                    </div>
                    <div class="relative w-full md:w-1/3 sm:w-full lg:mb-0 py-8  md:py-4 md:px-10">
                        <div class="h-full text-center">
                            <img
                                class="w-7 md:w-9 mb-2 md:mb-6 object-cover object-center inline-block"
                                src={youtube}
                                alt="Know How"
                                
                            />
                            <h3 className=' md:text-lg 2xl:text-xl text-primary '>Know How</h3>
                            <p class="font-medium text-sm md:text-base 2xl:text-lg mb-3 md:mb-4">Resources to help you learn about the latest Gazelle® products and tutorials to help you get the job done.</p>
                            <a target="_blank" href="https://www.youtube.com/channel/UC2K9BYZUSYvyCppaCeu1IdA" className='border absolute bottom-4 md:bottom-0 w-40 mr-auto ml-auto left-0 right-0 underline font-medium text-sm md:text-base 2xl:text-lg'>Watch Videos</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
