import React from 'react'
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const CategoryTile = props => {
    const { category } = props;
    return (
        <div class="group">
            <Link to={category.path}>
                <span class="relative flex items-end mx-auto w-full h-36 md:h-40 2xl:h-52 bg-black">
                    <div className="absolute h-full">
                        <GatsbyImage
                            class="inset-0 w-full h-full opacity-60 group-hover:opacity-100 animate transition-opacity duration-150"
                            image={getImage(category?.image?.localFile)}
                            alt={category.name}
                        />
                    </div>
                    <div
                        class="group-hover transition ease-in-out duration-300 slanted-border relative w-full md:w-5/6 2xl:w-3/4 py-2 2xl:py-3 md:pl-4 2xl:pl-8 text-white  bg-primary text-center md:text-left"
                    >
                        <span className='text-xs md:text-sm 2xl:text-lg'>{category.name}</span>
                    </div>
                </span>
            </Link>
        </div>
    )
}

export default CategoryTile